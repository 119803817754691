import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const liminaire = () => (
  <Layout>
    <SEO title="Dernières oeillades - Une Histoire" />
    <h3 className='underline-title'>Une Histoire</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Il escomptait l'excellence californienne <br />
        <span className="par-starting"></span>respectueuse <br />
        <span className="par-starting"></span>noble<br />
        <span className="par-starting"></span>fidèle comme une villageoise<br />
        <span className="par-starting"></span>génitrice d'une famille nombreuse d'Afrique<br />
      </p>
      <p>
        Dressées<br />
        <span className="par-starting"></span>les oreilles aux aguets d'une nouvelle radiodifusée<br />
        Il attendait que l'autocratique disciple de Bacchus <br />
        <span className="par-starting"></span>ingurgite sa dernière gorgée du Johnie Walker <br />
        <span className="par-starting"></span>pour décider de son sort fataliste.<br />
      </p>
      <p>
        Dans l'entretemps<br />
        <span className="par-starting"></span>des cancans des sycophantes agités fusèrent<br />
        <span className="par-starting"></span>et s'imbriquèrent en faisceaux ténébreux.<br />
      </p>
      <p>
        Sa barbe devait pousser et ses ongles s'allonger.<br />
      </p>
      <p>
        En pleine face fripée il est flanqué: <br />
        OKOZELA TROP! (*)<br />
      </p>
    </div>
    <hr/>
    <p className="font-italic">(*) Tu attendras très longtemps</p>
    <br />
    <br />
    <Link to="/dernieres-oeillades-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default liminaire
